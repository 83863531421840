/**
 *  Copy Bid service, used in Copy Year2Year wizard
 */
import { ActivatedRoute } from '@angular/router';
import { ApiUrlPrefix } from '@qv-common/enums';

import { BidRight, PriceProtectionState } from '@qv-bid/enums';
import { constants, resources } from '@qv-common/static';
import { CoreUtils } from '@qv-common/utils';
import { drugTermsConstants } from '@qv-term/constants';
import { TermName, TermSection } from '@qv-term/enums';
import {
  LatePaymentPenaltyBasis,
  LatePaymentPenaltyFrequency,
  LineOfBusiness,
  NoFaultTerminationClauseWhoMayInvoke,
  PaymentFrequencyLag,
  PaymentLag,
  PharmaAuditFrequency,
  RebateEligibility,
} from '@qv-term/enums/options';
import {
  NewPriceEffectiveDate,
  PriceProtectionIndex,
  PriceProtectionReset,
  WholesaleAcquisitionPrice
} from 'quantuvis-core-entities';
import { first } from 'rxjs/operators';
import { BidWizardService } from './bid-wizard.service';
import { UserService } from '@qv-common/services/auth/user.service';

export class CopyBidService extends BidWizardService {
  public static $inject = ['activatedRoute', 'translations', 'bidding', 'locking', 'util', 'apiService', 'userService'];
  private activatedRoute: ActivatedRoute;
  private translations;
  private bidding;
  private locking;
  private util;
  private apiService;
  private copyBidIds;
  private summaryTermNamesWithLoocks;
  private contractTermNamesWithLoocks;
  private metadata;
  private bidMetadata;
  private pharmaRights;
  private preserveOriginalPharmaRights;
  private i18n;

  constructor(activatedRoute: ActivatedRoute, translations, bidding, locking, util, apiService,
              private userService: UserService) {
    'ngInject';
    super();
    this.activatedRoute = activatedRoute;
    this.translations = translations;
    this.bidding = bidding;
    this.locking = locking;
    this.util = util;
    this.apiService = apiService;
    this.copyBidIds = [];
    this.summaryTermNamesWithLoocks = [
      constants.SUMMARY_TERMS.CONTRACT_START_DATE.label,
      constants.SUMMARY_TERMS.CONTRACT_END_DATE.label,
      constants.SUMMARY_TERMS.CONTRACT_EVERGREEN.label,
      constants.SUMMARY_TERMS.ATTACHMENTS.label,
      constants.SUMMARY_TERMS.WEBSITE_LINKS.label,
    ];
    this.contractTermNamesWithLoocks = [
      constants.BIDDING.LATE_PAYMENT_PENALTY_TERMS,
      constants.CONTRACT_TERMS.REBATE_ELIGIBILITY.label,
      constants.CONTRACT_TERMS.PAYMENT_FREQUENCY.label,
      constants.CONTRACT_TERMS.PAYMENT_LAG.label,
      constants.CONTRACT_TERMS.WHOLESALE_ACQUISITION_PRICE.label,
      constants.BIDDING.PHARMA_AUDIT_TERMS,
      constants.BIDDING.NO_FAULT_TERMINATION_CLAUSE,
      constants.CONTRACT_TERMS.ADMINISTRATION_FEE.label,
    ];
    this.translations.buildTranslations(this);
  }

  public addCopyBids(bidIds): void {
    this.copyBidIds = this.copyBidIds.concat(bidIds);
  }

  public getLockState(term): any {
    const isLocked = this.locking.isTermLocked(term, this.metadata)
      || this.locking.isTermsGroupLocked(this.util.getPathFromArray(term.definition.path), this.metadata);
    return isLocked
      ? constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE
      : constants.DRUG_TERM_GROUPS_STATE_VALUES.UNLOCKED_STATE;
  }

  public addOrRemovePharmaRight(termAllowed): void {
    this.addOrRemoveTerm(termAllowed, this.pharmaRights);
  }

  public addOrRemoveTerm(term, metaObject): void {
    if (CoreUtils.isNotDefined(metaObject[term.path])) {
      metaObject[term.path] = term.flags;
    } else {
      delete metaObject[term.path];
    }
  }

  public createMetadataObjectForSave(metadataObject): any[] {
    const metadataForSave = [];
    for (const metaPath in metadataObject) {
      if (metadataObject.hasOwnProperty(metaPath)) {
        metadataForSave.push({
          path: metaPath,
          flags: metadataObject[metaPath]
        });
      }
    }

    return metadataForSave;
  }

  public getRequestContactData(): any {
    const contractTerms = constants.CONTRACT_TERMS;
    const contractTermsKey = 'contractTerms';
    const preserveLockState = this.bidConfig.contractPreserveOriginalLocks;
    const paymentLock = this.getTermLockByName(`${contractTermsKey}.${constants.BIDDING.PAYMENT}`, preserveLockState);

    const contractData = {
      payment: {
        rebateEligibility:
          this.getDictionaryItemByValue(RebateEligibility, this.getTermValue(contractTerms.REBATE_ELIGIBILITY.label)),
        rebateEligibilityLock: paymentLock ? paymentLock : this.getTermLockByName(
          `${contractTermsKey}.${constants.BIDDING.PAYMENT}.${contractTerms.REBATE_ELIGIBILITY.label}`,
          preserveLockState
        ),
        wholesalePrice: this.getDictionaryItemByValue(
          WholesaleAcquisitionPrice,
          this.getTermValue(contractTerms.WHOLESALE_ACQUISITION_PRICE.label)
        ),
        wholesalePriceLock: paymentLock ? paymentLock :
          this.getTermLockByName(
            `${contractTermsKey}.${constants.BIDDING.PAYMENT}.${contractTerms.WHOLESALE_ACQUISITION_PRICE.label}`,
            preserveLockState
          ),
        lag: this.getDictionaryItemByValue(PaymentLag, this.getTermValue(contractTerms.PAYMENT_LAG.label)),
        lagLock: paymentLock ? paymentLock : this.getTermLockByName(
          `${contractTermsKey}.${constants.BIDDING.PAYMENT}.${contractTerms.PAYMENT_LAG.label}`,
          preserveLockState
        ),
        frequency: this.getDictionaryItemByValue(
          PaymentFrequencyLag,
          this.getTermValue(contractTerms.PAYMENT_FREQUENCY.label)
        ),
        frequencyLock: paymentLock ? paymentLock : this.getTermLockByName(
          `${contractTermsKey}.${constants.BIDDING.PAYMENT}.${contractTerms.PAYMENT_FREQUENCY.label}`,
          preserveLockState
        ),
      },
      audit: {
        isLocked: this.getTermLockByName(
          `${contractTermsKey}.${resources.BID_DETAILS.AUDIT_TERMS}`,
          preserveLockState
        ),
        frequency: this.getDictionaryItemByValue(
          PharmaAuditFrequency,
          this.getTermValue(contractTerms.PHARMA_AUDIT_TERMS_AUDIT_FREQUENCY.label)
        )
      },
      terminationClause: {
        isLocked: this.getTermLockByName(
          `${contractTermsKey}.${resources.BID_DETAILS.NO_FAULT_TERMINATION_CLAUSE}`,
          preserveLockState
        ),
        whoMayInvoke: this.getDictionaryItemByValue(
          NoFaultTerminationClauseWhoMayInvoke,
          this.getTermValue(contractTerms.NO_FAULT_TERMINATION_CLAUSE_WHO_MAY_INVOKE.label)
        )
      },
      penalty: {
        isLocked: this.getTermLockByName(
          `${contractTermsKey}.${constants.BIDDING.LATE_PAYMENT_PENALTY_TERMS}`,
          preserveLockState
        ),
        basis: this.getDictionaryItemByValue(
          LatePaymentPenaltyBasis,
          this.getTermValue(contractTerms.LATE_PAYMENT_PENALTY_TERMS_BASIS.label)
        ),
        frequency: this.getDictionaryItemByValue(
          LatePaymentPenaltyFrequency,
          this.getTermValue(contractTerms.LATE_PAYMENT_PENALTY_TERMS_FREQUENCY.label)
        ),
      },
      fee: {
        isLocked: this.getTermLockByName(
          `${contractTermsKey}.${resources.BID_DETAILS.FEES_SECTION}`,
          preserveLockState
        )
      },
    };

    this.updateTermPropertyByTermName(contractData, 'audit','priorNoticeRequired',
      contractTerms.PHARMA_AUDIT_TERMS_PRIOR_NOTICE_REQUIRED.label);
    this.updateTermPropertyByTermName(contractData, 'audit','lookBack',
      contractTerms.PHARMA_AUDIT_TERMS_LOOK_BACK.label);
    this.updateTermPropertyByTermName(contractData, 'terminationClause','priorNoticeRequired',
      contractTerms.NO_FAULT_TERMINATION_CLAUSE_PRIOR_NOTICE_REQUIRED.label);
    this.updateTermPropertyByTermName(contractData, 'penalty','value',
      contractTerms.LATE_PAYMENT_PENALTY_TERMS_VALUE.label);
    this.updateTermPropertyByTermName(contractData, 'fee','administrationFee',
      contractTerms.ADMINISTRATION_FEE.label);

    return contractData;
  }

  private updateTermPropertyByTermName(contractData: any, objectName: string, fieldName: string, termName: string): void {
    const term: any = this.getTermByName(termName);

    if (!term.definition.keepOriginalValue) {
      contractData[objectName][fieldName] = this.getValueFromTerm(term);
    }
  }

  public getRequestSummaryData(): { [p: string]: any | boolean; isBinding: any } {
    const summaryTerms = constants.SUMMARY_TERMS;
    const summaryTermsKey = 'summaryTerms';

    const webSiteLinks = this.getTermValue(summaryTerms.WEBSITE_LINKS.label);

    const instructionValue = this.getTermValue(summaryTerms.INSTRUCTIONS.label);
    const preserveLockState = this.bidConfig.summaryPreserveOriginalLocks;

    return {
      [TermName.RFP_TITLE]: this.getTermValue(summaryTerms.RFP_TITLE.label),
      [TermName.LINE_OF_BUSINESS]: this.getLineOfBusinessValue(summaryTerms.LINE_OF_BUSINESS.label),
      isBinding: this.getTermValue(summaryTerms.BINDING_BID.label),
      [TermName.RFP_START_DATE]: this.getTermValue(summaryTerms.RFP_START_DATE.label),
      [TermName.RFP_DUE_DATE]: this.getTermValue(summaryTerms.RFP_DUE_DATE.label),
      [TermName.CONTRACT_START_DATE]: this.getTermValue(summaryTerms.CONTRACT_START_DATE.label),
      [TermName.getTermNameLock(TermName.CONTRACT_START_DATE)]:
        this.getTermLockByName(`${summaryTermsKey}.${summaryTerms.CONTRACT_START_DATE.label}`, preserveLockState),
      [TermName.CONTRACT_END_DATE]: this.getTermValue(summaryTerms.CONTRACT_END_DATE.label),
      [TermName.getTermNameLock(TermName.CONTRACT_END_DATE)]:
        this.getTermLockByName(`${summaryTermsKey}.${summaryTerms.CONTRACT_END_DATE.label}`, preserveLockState),
      [TermName.CONTRACT_EVERGREEN]: this.getTermValue(summaryTerms.CONTRACT_EVERGREEN.label),
      [TermName.getTermNameLock(TermName.CONTRACT_EVERGREEN)]:
        this.getTermLockByName(`${summaryTermsKey}.${summaryTerms.CONTRACT_EVERGREEN.label}`, preserveLockState),
      [TermName.INSTRUCTIONS]: instructionValue ? instructionValue.text : null,
      [TermName.getTermNameLock(TermName.ATTACHMENTS)]:
        this.getTermLockByName(`${summaryTermsKey}.${summaryTerms.ATTACHMENTS.label}`, preserveLockState),
      [TermName.ATTACHMENTS]: this.getAttachments(summaryTerms.ATTACHMENTS.label),
      [TermName.WEBSITE_LINKS]: webSiteLinks ? webSiteLinks.map((obj) => obj.link) : webSiteLinks,
      [TermName.getTermNameLock(TermName.WEBSITE_LINKS)]:
        this.getTermLockByName(`${summaryTermsKey}.${summaryTerms.WEBSITE_LINKS.label}`, preserveLockState),
    };
  }

  public getTermValue(termName, asKeepOriginalValue = null): any {
    const term: any = this.getTermByName(termName);

    if (term.definition.keepOriginalValue) {
      return asKeepOriginalValue;
    }

    return this.getValueFromTerm(term);
  }

  private getValueFromTerm(term: any): any {
    if (typeof term.definition.getValueToSave === 'function') {
      return term.definition.getValueToSave(term);
    }

    return term.value;
  }

  public getLineOfBusinessValue(termName, asKeepOriginalValue = null): any {
    const term: any = this.getTermByName(termName);

    if (term.definition.keepOriginalValue) {
      return asKeepOriginalValue;
    } else {
      const lineOfBusinessKey: string = term.value ? term.value.toUpperCase() : '';
      const enumOption = LineOfBusiness[lineOfBusinessKey.toUpperCase()];

      return LineOfBusiness.getValue(enumOption);
    }
  }

  public getPriceProtectionTermValue(termName: string): { value: string, keepOriginalValue: boolean } {
    const term: any = this.getTermByName(termName);
    const keepOriginalValue = term.definition.keepOriginalValue;
    const data = {
      value: term.value,
      keepOriginalValue,
    };

    if (keepOriginalValue) {
      data.value = null;
    } else if (typeof term.definition.getValueToSave === 'function') {
      data.value = term.definition.getValueToSave(term);
    }

    return data;
  }

  public getTermLockByName(key, preserveLockState): null | boolean {
    if (preserveLockState) return null;

    const metadata = Object.assign({}, this.metadata, this.bidMetadata);
    if (!Object.keys(metadata).length) return false;
    if (!metadata[key]) return false;
    return metadata[key].state === constants.DRUG_TERM_GROUPS_STATE_VALUES.LOCKED_STATE.state;
  }

  public getAttachments(term): any[] | null {
    const requestData = [];
    const user = this.userService.user.getValue();
    const attachments = this.getTermValue(term);
    if (!Array.isArray(attachments)) return null;
    attachments.forEach((attachment) => {
      requestData.push({
        id: attachment.attachmentId,
        summaryId: null,
        isLegalAttestation: false,
        baseCompany: {
          id: user.company.id,
          name: user.company.name,
          type: user.company.companyType,
        }
      });
    });

    return requestData;
  }

  public getDictionaryItemByValue(enumInstance, value): any {
    if (value === null) return value;
    return enumInstance.getValues().find((dictionaryItem) =>
      dictionaryItem.name.toLocaleLowerCase() === value.toLocaleLowerCase());
  }

  public getPharmaRight(key, defaultValue): null | boolean {
    if (this.preserveOriginalPharmaRights) return null;
    return this.pharmaRights[key] ? !defaultValue : defaultValue;
  }

  public initCopyBidConfig(): void {
    this.metadata = {};
    this.pharmaRights = {};
    this.preserveOriginalPharmaRights = true;
    this.bidMetadata = {};
    this.bidConfig = {
      summary: [],
      contract: [],
      editable: true,
      isNew: true,
      summaryPreserveOriginalLocks: true,
      contractPreserveOriginalLocks: true,
      copyHistoricComments: true,
      copyHistoricNotes: true,
      copyBaseRebate: true,
      copyMinBaseRebate: true,
      copyMarketShare: true,
      removeDismissedScenarios: false,
    };
  }

  public isShownLegalAttestation(): boolean {
    const bindingTerm = this.getTermByName(constants.SUMMARY_TERMS.BINDING_BID.label);

    const termValue = this.bidding.getSaveValueForTerm(bindingTerm);

    return termValue.value === true
      || termValue.value === resources.NewBid.KEEP_ORIGINAL_VALUE && this.activatedRoute.snapshot.queryParams.isBinding;
  }

  public clearCopyBids(): void {
    this.copyBidIds = [];
  }

  public clearPharmaRights(): void {
    this.pharmaRights = {};
  }

  public getAllowQuestions(): any[] {
    // Switch array's indexes with values to get order values for sorting the list in UI
    const order = this.i18n.NewBid.AllowedOrder.reduce((prev: Object, curr: string, index: number) => {
      prev[curr] = index;
      return prev;
    }, {});
    const questions = [];
    const allowedQuestions = Object.assign({}, this.i18n.NewBid.DrugTermsAllowed, this.i18n.NewBid.BidTermsAllowed);

    for (const questionKey in allowedQuestions) {
      if (allowedQuestions.hasOwnProperty(questionKey)) {
        const isDrugQuestion = this.i18n.NewBid.DrugTermsAllowed[questionKey] !== undefined;
        questions.push({
          text: isDrugQuestion ? this.i18n.NewBid.DrugTermsAllowed[questionKey]
            : this.i18n.NewBid.BidTermsAllowed[questionKey],
          path: this.getPath(questionKey),
          flags: isDrugQuestion ? this.getFlagForAllowedDrugTerm(questionKey)
            : this.getFlagForAllowedBidTerm(questionKey),
          checked: false,
          option: isDrugQuestion ? 'drugTerm' : 'bidTerm',
          order: parseInt(order[questionKey])
        });
      }
    }

    return questions;
  }

  public getBidConfig(): any {
    return this.bidConfig;
  }

  public getCopyBids(): any {
    return this.copyBidIds;
  }

  public getBidMetadata(): any {
    return this.bidMetadata;
  }

  public setBidMetadata(bidMetadata): void {
    this.bidMetadata = bidMetadata;
  }

  public getMetadata(): any {
    return this.metadata;
  }

  public setMetadata(metadata): void {
    this.metadata = metadata;
  }

  public removeLocksFromBidMetadata(): void {
    this.summaryTermNamesWithLoocks.forEach(name => {
      this.removeLocks(name, this.bidMetadata);
    });
  }

  public removeLocksFromMetadata(): void {
    this.contractTermNamesWithLoocks.forEach(name => {
      this.removeLocks(name, this.metadata);
    });
    this.prepareAdminFeeLockForResponse(this.metadata);
  }

  public removeLocks(termName, source): void {
    const term: any = this.getTermByName(termName);
    const termPath = this.util.getPathFromArray(term.definition.path);
    delete source[termPath];
  }

  public getFlagForAllowedBidTerm(termKey): { state: 'locked' } | { state: 'hidden' } {
    if (this.i18n.NewBid.BidTermsAllowed[termKey] === this.i18n.NewBid.BidTermsAllowed.LOCK_NOTES ||
      this.i18n.NewBid.BidTermsAllowed[termKey] === this.i18n.NewBid.BidTermsAllowed.ALLOW_MARKET_BASKET ||
      this.i18n.NewBid.BidTermsAllowed[termKey] === this.i18n.NewBid.BidTermsAllowed.ALLOW_NDC_OFFERS) {
      return this.locking.getLockStateObject();
    }

    return this.locking.getHiddenStateObject();
  }

  public getFlagForAllowedDrugTerm(termKey): { state: 'hidden' } {
    return this.i18n.NewBid.DrugTermsAllowed[termKey] === this.i18n.NewBid.DrugTermsAllowed.ALLOW_MARKET_SHARE_REBATE ?
      this.locking.getHiddenStateObject() : this.locking.getLockStateObject();
  }

  public getPath(termKey): any {
    return constants.DRUG_TERM_PATHS[termKey] !== undefined
      ? this.util.getPathFromArray(constants.DRUG_TERM_PATHS[termKey]) :
      this.util.getPathFromArray(constants.BID_TERM_PATHS[termKey]);
  }

  public getValuesToSave(): any {
    return this.getRequestData();
  }

  public getRequestData(): any {
    const priceProtectionStateValue = this.metadata['drugList.priceProtection']
      ? this.metadata['drugList.priceProtection'].state : null;
    return {
      sourceIds: this.copyBidIds,
      [TermSection.SUMMARY]: this.getRequestSummaryData(),
      [TermSection.CONTRACT]: this.getRequestContactData(),
      pharmaRights: this.preserveOriginalPharmaRights ? null : [
        {
          right: BidRight.ALLOW_MARKET_SHARE_REBATE_OFFERS_FROM_PHARMA,
          isActive: this.getPharmaRight(constants.DRUG_TERM_PATHS.ALLOW_MARKET_SHARE_REBATE.join('.'), true),
        },
        {
          right: BidRight.ALLOW_PHARMA_TO_CREATE_SCENARIOS,
          isActive: this.getPharmaRight(constants.BID_TERM_PATHS.ALLOW_SCENARIOS.join('.'), true),
        },
        {
          right: BidRight.ALLOW_PHARMA_TO_CREATE_OR_MODIFY_CB,
          isActive: this.getPharmaRight(constants.BID_TERM_PATHS.ALLOW_CONTRACTED_BUSINESS.join('.'), true),
        },
        {
          right: BidRight.LOCK_ALL_NOTES_FIELDS,
          isActive: this.getPharmaRight(constants.DRUG_TERM_PATHS.ALLOW_NOTES.join('.'), false),
        },
        {
          right: BidRight.ALLOW_DEPENDENCIES_FROM_PHARMA,
          isActive: this.getPharmaRight(constants.BID_TERM_PATHS.ALLOW_DEPENDENCIES.join('.'), true),
        },
        {
          right: BidRight.ALLOW_OFFERS_AT_THE_NDC_LEVEL_FROM_PHARMA,
          isActive: this.getPharmaRight(constants.DRUG_TERM_PATHS.ALLOW_NDC_OFFERS.join('.'), true),
        },
        {
          right: BidRight.ALLOW_PHARMA_TO_NEGOTIATE_MB,
          isActive: this.getPharmaRight(constants.DRUG_TERM_PATHS.ALLOW_MARKET_BASKET.join('.'), true),
        },
      ],
      keepHistoricNotes: this.bidConfig.copyHistoricNotes,
      keepHistoricComments: this.bidConfig.copyHistoricComments,
      keepBaseRebate: this.bidConfig.copyBaseRebate,
      keepMinBaseRebate: this.bidConfig.copyMinBaseRebate,
      keepMarketShare: this.bidConfig.copyMarketShare,
      removeDismissedScenarios: this.bidConfig.removeDismissedScenarios,
      [TermSection.PRICE_PROTECTION]: {
        state: this.getDictionaryItemByValue(PriceProtectionState, priceProtectionStateValue),
        netEffectivePrice: this.getPriceProtectionTermValue(drugTermsConstants[TermName.NET_EFFECTIVE_PRICE].title),
        index: this.getDictionaryItemByValue(
          PriceProtectionIndex,
          this.getTermValue(drugTermsConstants[TermName.INDEX].title)
        ),
        threshold: this.getPriceProtectionTermValue(drugTermsConstants[TermName.THRESHOLD].title),
        reset: this.getDictionaryItemByValue(
          PriceProtectionReset,
          this.getTermValue(drugTermsConstants[TermName.RESET].title)
        ),
        newPriceEffectiveDate: this.getDictionaryItemByValue(
          NewPriceEffectiveDate,
          this.getTermValue(drugTermsConstants[TermName.NEW_PRICE_EFFECTIVE_DATE].title)
        ),
        baselineStartDate: this.getPriceProtectionTermValue(drugTermsConstants[TermName.BASELINE_START_DATE].title),
      },
    };
  }

  public getTermByName(name): any {
    return this.bidConfig.summary.concat(this.bidConfig.contract)
      .concat(this.bidConfig.drugTerms).find(search => search.name === name);
  }

  public lockOrUnlock(termsToLockOrUnlock, addGroupPath): void {
    if (addGroupPath) {
      this.locking.lockOrUnlockAllTermsInGroup(termsToLockOrUnlock, this.metadata, this.bidConfig.contract, undefined);
    } else {
      for (const term of termsToLockOrUnlock) {
        if (this.util.isPathDefined(term, 'definition.saveToBidMeta') && term.definition.saveToBidMeta === true) {
          this.locking.lockOrUnlockTerm(term, this.bidMetadata, this.bidConfig.contract, undefined);
        } else {
          this.locking.lockOrUnlockTerm(term, this.metadata, this.bidConfig.contract, undefined);
        }
      }
    }
  }

  public setLocksInMetadata(term, state): void {
    const termPath = this.util.getPathFromArray(term.definition.path);
    if (this.util.isPathDefined(term, 'definition.saveToBidMeta') && term.definition.saveToBidMeta) {
      if (!this.bidMetadata[termPath]) {
        this.bidMetadata[termPath] = state;
      }
    } else {
      if (!this.metadata[termPath]) {
        this.metadata[termPath] = state;
      }
    }
  }

  public prepareBidTerms(): void {
    const bidTemplate = this.bidding.BiddingData();
    let summary = bidTemplate.summaryTerms;
    // Remove bidding plan type term from Copy Year2Year wizard
    summary = summary.filter(term => term.name !== constants.BIDDING.PLAN_TYPE);
    const contractTerms = bidTemplate.contractTerms;
    const drugListTerms = bidTemplate.drugListTerms;
    const settings = {
      allowKeepOriginValue: true
    };
    this.bidConfig.summary = this.bidding.prepareTerms(null, summary, settings);
    this.bidConfig.drugTerms = this.bidding.prepareTerms(null, drugListTerms, settings);
    this.bidConfig.contract = this.bidding.prepareTerms(null, contractTerms, settings);
  }

  public prepareMetadata(): void {
    if (this.pharmaRights !== undefined) {
      for (const question of this.getAllowQuestions()) {
        if (question.option === 'drugTerm') {
          this.pharmaRights[question.path] = question.flags;
        } else if (question.option === 'bidTerm' && question.text !== this.i18n.NewBid.BidTermsAllowed.LOCK_NOTES) {
          this.pharmaRights[question.path] = question.flags;
        }
      }
    }
  }

  public prepareAdminFeeLockForResponse(metadata): void {
    const contractTerms = constants.BID_SECTIONS.CONTRACT_TERMS;
    const adminFeeLabel = constants.CONTRACT_TERMS.ADMINISTRATION_FEE.label;
    const adminFeeFullKey = `${contractTerms}.${this.i18n.BID_DETAILS.FEES_SECTION}.${adminFeeLabel}`;
    const adminFeeKey = `${contractTerms}.${this.i18n.BID_DETAILS.FEES_SECTION}`;
    const adminFeeState = metadata[adminFeeFullKey];
    delete metadata[adminFeeFullKey];
    if (adminFeeState) {
      metadata[adminFeeKey] = adminFeeState;
    }
  }

  public saveBids(): Promise<any> {
    const objectToCopy = this.getValuesToSave();
    return this.apiService.post(`${ApiUrlPrefix.BIDS}/copy`, objectToCopy).pipe(first()).toPromise();
  }

  /**
   * Set up the metadata for Price Protection section at create RFP (in pre-populate Price Protection section).
   *
   * @param selectedState - selected state for price protection metadata entry
   */
  public setMetadataForPriceProtection(selectedState): void {
    const path = this.util.getPathFromArray(constants.DRUG_TERM_PATHS.ALLOW_PRICE_PROTECTION);

    /**
     * If selected state is "required", do not add info to metadata, as this is interpreted on UI and print bid
     * as being the default state for Price Protection section when metadata has no entry for the path
     * "drugList.priceProtection".
     */
    if (selectedState === constants.DRUG_TERM_GROUPS_STATE_VALUES.REQUIRED_STATE.state && this.metadata[path] ||
      selectedState === resources.NewBid.KEEP_ORIGINAL_VALUE) {
      // If selected state is "required" and metadata already has info about Price Protection then remove that entry
      delete this.metadata[path];
    } else {
      this.metadata[path] = {
        state: selectedState
      };
    }
  }

  public getLockUnlockIconName(term): any | null {
    return this.locking.getLockUnlockIconName(term, this.metadata);
  }

  public getLockUnlockIconClass(term): any | null {
    return this.locking.getLockUnlockIconClass(term, this.metadata);
  }
}

// tslint:disable-next-line:max-file-line-count
